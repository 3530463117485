import React from 'react'
import PropTypes from 'prop-types'

import BackPrev from '@/components/BackPrev/index.jsx'
import { Container } from './styled.js'

function Layout({ children }) {
  return (
    <>
      <Container>
        <main>{children}</main>
      </Container>
      <BackPrev />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
