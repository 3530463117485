import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { Link } from 'gatsby'

export const AboutContainer = styled.div`
  margin: 0 auto;
  width: 100%;

  .container {
    padding: 30px 15px;

    article {
      padding: 45px;
      font-size: ${theme.mainFontSize};

      span {
        color: ${theme.mainColor};
      }
    }

    .container-info-wrapper {
      display: none;
      width: 100%;
      padding: 15px 5px;
      text-align: center;

      h1 {
        margin: 0;
        letter-spacing: 2px;
        font-size: calc(${theme.mainFontSize} + 8px);
        font-weight: 600;
      }

      h2 {
        margin: 0;
        font-size: ${theme.mainFontSize};
      }
    }

    h3 {
      font-size: ${theme.mainFontSize};
      width: 100%;
    }
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    width: 40%;

    .container {
      padding: 0 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .container-info-wrapper {
        display: inline-block;
      }
    }
  }
  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
  }
`

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    height: 100vh;
    flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  }
`

export const Cover = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;

  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: ${props => `url(${props.img})`};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: black;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 60%
    );
  }

  color: white;

  .cover-info-wrapper {
    width: 95%;
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 5px;
    transform: translateX(-50%);
    text-align: center;

    h1 {
      margin: 0;
      letter-spacing: 2px;
      font-size: calc(${theme.mainFontSize} + 8px);
      font-weight: 600;
    }

    h2 {
      margin: 0;
      font-size: ${theme.mainFontSize};
    }
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    width: 60%;
    height: 100%;
    padding-bottom: unset;
    .cover-info-wrapper {
      display: none;
    }
    &:before {
      display: none;
    }
  }
`

export const DirectingButtonGroupWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    background: transparent;
    border: none;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    color: ${theme.mainColor};

    &:hover {
      color: ${theme.mainColorHover};
    }

    svg {
      font-size: 40px;
    }

    p {
      font-size: 14px;
      margin: 0;
      white-space: nowrap;
    }
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    margin: 15px 0;
  }

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    justify-content: flex-start;

    button {
      margin: 15px;
    }
  }
`

export const LinkGroupWrapper = styled.div`
  padding: 15px 0;
  width: 150px;
  margin: 0 auto;

  a {
    color: ${theme.mainFontColor};
  }

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    margin: 0 auto 0 0;
  }
`

export const LinkButton = styled(Link)`
  display: inline-block;
  width: 100%;
  height: 40px;
  background: ${theme.mainColor};
  cursor: pointer;
  box-shadow: 0 0 2px #fbfbfb;
  color: white;
  text-decoration: unset;
  font-size: calc(${theme.mainFontSize} - 2px);
  line-height: 40px;
  text-align: center;

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    width: 90%;
    &:hover {
      background: ${theme.mainColorHover};
    }
  }

  @media screen and (min-width: ${theme.mediaQueryDesktopWide}) {
    width: 80%;
  }
`

export const DisabledButton = styled.a`
  display: inline-block;
  width: 100%;
  height: 40px;
  background: ${theme.mainFontColor};
  cursor: not-allowed;
  box-shadow: 0 0 2px #fbfbfb;
  color: white;
  text-decoration: unset;
  font-size: calc(${theme.mainFontSize} - 2px);
  line-height: 40px;
  text-align: center;

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    width: 90%;
  }

  @media screen and (min-width: ${theme.mediaQueryDesktopWide}) {
    width: 80%;
  }
`

export const Content = styled.div`
  width: 100%;

  @media screen and (min-width: ${theme.mediaQueryMobile}) {
    width: 80%;
  }
`

export const AButton = styled.a`
  display: inline-block;
  width: 100%;
  height: 40px;
  background: ${theme.mainColor};
  cursor: pointer;
  box-shadow: 0 0 2px #fbfbfb;
  color: white;
  text-decoration: unset;
  font-size: calc(${theme.mainFontSize} - 2px);
  line-height: 40px;
  text-align: center;

  @media screen and (min-width: ${theme.mediaQueryDesktop}) {
    width: 90%;
    &:hover {
      background: ${theme.mainColorHover};
    }
  }

  @media screen and (min-width: ${theme.mediaQueryDesktopWide}) {
    width: 80%;
  }
`
