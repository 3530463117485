import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCode,
  faPenNib,
  faRoute,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'

import Layout from '@/layouts/AboutMeLayout/index.jsx'
import LinkGroup from '@/components/LinkGroup'

import mainImage from '@/images/about-me-main.jpeg'
import businessImage from '@/images/about-me-business.jpeg'
import careerImage from '@/images/about-me-career.jpg'
import blogImage from '@/images/about-me-blog.jpg'
import travelImage from '@/images/about-me-travel.jpeg'

import {
  AboutContainer,
  Cover,
  LinkButton,
  LinkGroupWrapper,
  AButton,
  DirectingButtonGroupWrapper,
  BlockContainer,
  Content,
} from './styled.js'
import { STATIC_ROUTER_PATH } from '@/common/constants/routers'
import { DisabledButton } from '@/views/AboutMe/styled'

const main = {
  img: mainImage,
  title: 'Engine Lin',
  subtitle: '創辦人/工程師/部落客/背包客',
  button: (
    <Button to="mailto: linengine@gmail.com.tw" isLink={false}>
      聯絡我
    </Button>
  ),
  reverse: false,
}

const business = {
  id: 'business',
  img: businessImage,
  title: '當一位創辦人',
  subtitle: '十六進位有限公司 共同創辦人',
  button: (
    <Button to="https://16n.com.tw/" isLink={true}>
      網頁專案合作
    </Button>
  ),
  reverse: true,
}

const career = {
  id: 'career',
  img: careerImage,
  title: '當一位工程師',
  subtitle: '三年前端工程師經驗，熱愛技術',
  button: (
    <Button to="https://www.cakeresume.com/linengine" isLink={false}>
      需要前端 Leader
    </Button>
  ),
  reverse: false,
}

const blog = {
  id: 'blog',
  img: blogImage,
  title: '當一位部落客',
  subtitle: '維持寫作十年，寫的出來才是自己的',
  button: (
    <Button to={STATIC_ROUTER_PATH.HOME} isLink={true}>
      看部落格文章
    </Button>
  ),
  reverse: true,
}

const travel = {
  id: 'travel',
  img: travelImage,
  title: '當一位背包客',
  subtitle: '2016年完成八個月環遊世界的旅行',
  button: <Button disabled={true}>品味旅遊細節</Button>,
  reverse: false,
}

function AboutMe() {
  const handleDirectingButtonClick = (e, target) => {
    e.preventDefault()
    const { offsetTop } = document.getElementById(target)
    const targetTop =
      window.pageYOffset ||
      document.body.scrollTop ||
      document.documentElement.scrollTop
    const SCROLL_DURATION = 300 //ms
    const DEFAULT_FPS = 60
    const SCROLL_DISTANCE =
      (Math.abs(offsetTop - targetTop) * (1000 / SCROLL_DURATION)) / DEFAULT_FPS

    function update() {
      const top =
        window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop
      const { clientHeight, scrollHeight } = document.documentElement

      // Scroll to bottom
      if (top >= scrollHeight - clientHeight) {
        window.scrollTo(0, scrollHeight - clientHeight)
        return
      }

      // Scroll to place in range
      if (
        top >= offsetTop - SCROLL_DISTANCE &&
        top <= offsetTop + SCROLL_DISTANCE
      ) {
        window.scrollTo(0, offsetTop)
        return
      }

      if (top > offsetTop) {
        window.scrollTo(0, top - SCROLL_DISTANCE)
        window.requestAnimationFrame(update)
      }
      if (top < offsetTop) {
        window.scrollTo(0, top + SCROLL_DISTANCE)
        window.requestAnimationFrame(update)
      }
    }

    window.requestAnimationFrame(update)
  }

  return (
    <Layout>
      {/*Main Block*/}
      <Block {...main}>
        <article>
          Hi, I'm <span>EngineLin</span>, 或者您也可以叫我<span>引擎</span>。
          <br />
          我在網頁前端開發團隊擔任工程師已經有三年，經歷過許多複雜介面的應用網頁開發。
          <br />
          另外我也熱衷閱讀、寫作、專研技術、教學、旅遊。
        </article>

        <Content>
          <h3>多認識我一點？</h3>
          <DirectingButtonGroupWrapper>
            <button onClick={e => handleDirectingButtonClick(e, 'business')}>
              <FontAwesomeIcon icon={faUserTie} />
              <p>經營者</p>
            </button>
            <button onClick={e => handleDirectingButtonClick(e, 'career')}>
              <FontAwesomeIcon icon={faCode} />
              <p>工程師</p>
            </button>
            <button onClick={e => handleDirectingButtonClick(e, 'blog')}>
              <FontAwesomeIcon icon={faPenNib} />
              <p>部落客</p>
            </button>
            <button onClick={e => handleDirectingButtonClick(e, 'travel')}>
              <FontAwesomeIcon icon={faRoute} />
              <p>背包客</p>
            </button>
          </DirectingButtonGroupWrapper>

          <LinkGroupWrapper>
            <LinkGroup />
          </LinkGroupWrapper>
        </Content>
      </Block>

      {/*Business Block*/}
      <Block {...business}>
        <article>
          2019年創立<span>十六進位有限公司</span>
          ，致力於提供前端網頁的開發人力。
          <br />
          公司與優質的工程師配合，會針對交出的程式碼進行品質管理。
        </article>
      </Block>

      {/*Career Block*/}
      <Block {...career}>
        <article>
          2017年進入前端領域，熱愛技術與分享。擅長簡化複雜的改念與溝通，有
          <span>跨部門溝通</span>的強項。
          <br />
          偏好開發<span>複雜的介面</span>、追求使用者體驗的產品。
        </article>
      </Block>

      {/*Blog Block*/}
      <Block {...blog}>
        <article>
          我喜歡閱讀，也喜歡動畫，不過最喜歡的還是<span>持續的紀錄</span>。
          <br />
          能夠寫出文章，才算是成功內化那份知識。
        </article>
        <Content>
          <h3>有沒有寫過熱門文章？</h3>
          <a href="https://bit.ly/3dsXMTz">
            <img src="" alt="" />
          </a>
        </Content>
      </Block>

      {/*Travel Block*/}
      <Block {...travel}>
        <article>
          大三那一年我下定決心要環遊世界，研究了省錢旅遊的方式，做足了
          <span>旅遊的準備</span>。
          <br />
          大四那年加強了英語能力、畢業那年在台灣搭便車環島、第一份工作用了一年半存了
          <span>二十萬臺幣</span>。
          <br />
          設定好目標，一步步做好準備。
        </article>
        {/*<h3>搭便車環島記錄在哪？</h3>*/}
      </Block>
    </Layout>
  )
}

function Block({
  id,
  img,
  title,
  description,
  button,
  reverse = false,
  children,
}) {
  return (
    <BlockContainer id={id} reverse={reverse}>
      <Cover img={img}>
        <div className="cover-info-wrapper">
          <h1>{title}</h1>
          <h2>{description}</h2>
        </div>
      </Cover>

      <AboutContainer>
        <div className="container">
          <div className="container-info-wrapper">
            <h1>{title}</h1>
            <h2>{description}</h2>
          </div>

          {button}
          {children}
        </div>
      </AboutContainer>
    </BlockContainer>
  )
}

function Button({ to, isLink, disabled, children }) {
  if (disabled) {
    return (
      <DisabledButton
        href="#"
        onClick={e => {
          e.preventDefault()
        }}
      >
        {children}
      </DisabledButton>
    )
  }
  if (isLink) {
    return <LinkButton to={to}>{children}</LinkButton>
  } else {
    return <AButton href={to}>{children}</AButton>
  }
}

export default AboutMe
