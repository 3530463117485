import styled from 'styled-components'
import { theme } from '@/styles/theme'
import { Link } from 'gatsby'

const { transitionDuration, mainColor, mainColorHover } = theme
const size = '50px'

export const BackTopButton = styled(Link)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${size};
  height: ${size};
  font-size: 25px;
  right: 1em;
  bottom: 3em;
  text-align: center;
  color: #fff;
  transition: background ${transitionDuration}, ${transitionDuration};
  background: ${mainColor};
  background: linear-gradient(
    to right,
    ${mainColor} 35%,
    ${mainColorHover} 100%
  );
  background-size: 200% auto;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  z-index: 100;

  &:hover {
    background-position: right center;
  }

  &.active {
    opacity: 1;
  }
`
