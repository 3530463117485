import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { BackTopButton } from './styled.js'
import { STATIC_ROUTER_PATH } from "@/common/constants/routers"

function BackPrev() {
  return (
    <BackTopButton to={STATIC_ROUTER_PATH.HOME} className="active">
      <FontAwesomeIcon icon={faArrowLeft} />
    </BackTopButton>
  )
}

export default BackPrev
